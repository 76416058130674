
@font-face {
  font-family: 'renner';
  src: url('../assets/fonts/renner-book.otf') format("opentype"); }

@font-face {
  font-family: 'renner-light';
  src: url('../assets/fonts/renner-light.otf') format("opentype"); }

@font-face {
  font-family: 'HomepageBaukasten';
  src: url('../assets/fonts/HomepageBaukasten-Book.otf') format("opentype"); }

$accent-color: #06376C;
$dark-background: #393939;
$light-background: #EFEFEF;
$primary-color: #6DABFF;
$secondary-color: #6997CB;
$valert-black: #000000;
$valert-white: #ffffff;
$valcomColor: #0075c9;
$valert-gray: #5A5859;
$login-background: #000829;

$body-font: renner;
$header-font: HomepageBaukasten;

.text-white {
  color: $valert-white; }

$nav-default-color: #9b9b9b;
$nav-decored-color: #b1d4e9;
$delete-button-color: #f76b1c;
$delete-button-hover-color: #ec5a08;
$cancel-button-color: #dddddd;
$cancel-button-hover-color: #bbbbbb;
$login-button-color: #0066b0;
$login-form-text-color: #666666;
$nav-logout-button-default-color: #EA3530;
$nav-logout-button-decored-color: #E91F19;
$not-found-page-background-color: #ac3832;

$button-default-height: 40px;

$form-header: 2rem;
$form-section: 1.75rem;
$form-label: 1.5rem;
$form-sub-lable: 1.25rem;
$form-item: 1rem;
