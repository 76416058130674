@import "./Variables";
@import './placeholders';

@mixin transform($property) {
    -webkit-transform: $property;
    -ms-transform: $property;
    transform: $property; }

.admin-container {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    @media screen and ( max-width: 950px ) {
        flex-flow: column wrap;
        justify-content: center;
        align-items: center; } }

.addBtnContainer {
    display: flex;
    justify-content: flex-end;
    svg {
        cursor: pointer; } }

.addRoleBtn {
    @include transform(rotate(0deg)); }

.table-container {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 60%;
    @media screen and ( max-width: 950px ) {
        width: 100%; }
    @media screen and ( min-width: 950px ) {
        height: 85vh; } }

.card-container {
    height: 75vh;
    overflow: auto;
    margin-top: 10px;
    @extend %scrollbar; }

.card-container-sm {
    height: 25vh;
    overflow: auto;
    margin-top: 10px;
    @extend %scrollbar; }

.token-container {
    width: 39%;
    padding: 0 0 0 2rem;
    @media screen and ( max-width: 950px ) {
        width: 100%;
        margin-bottom: 1rem;
        padding: 0; } }

.input-file {
    display: none; }

.input-file-label {
    margin: 0;
    padding: 0; }

.form-check {
    padding: 0 1rem 0 0!important; }

.form-checkbox {
    margin-left: 5px; }

.admin-buttons {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    .primary-button {
        width: 180px;
        margin: 5px; }
    @media screen and ( min-width: 951px) and (max-width: 1061px) {
        justify-content: space-evenly; }
    @media screen and ( max-width: 697px ) {
        flex-flow: column;
        align-items: center; } }
