@import './Variables.sass';
@import './placeholders';

.navbar {
  @media only screen and (min-width:768px) {
    padding: 0.50rem 0 0.75rem 0;
    z-index: 20; } }

.navbar-button {
  margin-right: 1rem;
  border: 1px solid;
  border-color: $accent-color;
  border-radius: 10px;
  color: $accent-color;
  cursor: pointer;
  background-color: $valert-white;
  padding: 1px 24px 1px 24px; }

.navbar-collapse {
  height: calc(100vh - 55px);
  position: relative;
  background-color: #06376C;
  z-index: 20;
  ul {
    list-style-type: none;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%); } }

.fakeNav {
  z-index: -10; }

.navbar-light {
  background-color: $valert-white;
  border-bottom: 1px solid #bdbdbd;
  @media only screen and (min-width: 768px) {
    height: 60px; } }

.navbar-light .navbar-toggler {
  border: none; }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255,255,255, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbarValcom {
  background-color: #06376C;
  position: fixed;
  width: 100%;
  margin-bottom: 60px;
  z-index: 30;
  top: 0;
  left: 0;
  a {
    color: #ffffff; }
  ul {
    vertical-align: middle;
    margin: auto;
    font-size: 2rem;
    padding-left: 0px;
    width: 100%;
    li {
      padding: 5px 0px 5px 0px;
      text-align: center; } }
  .navbar-button {
    margin-top: 10px !important;
    margin: auto;
    width: 175px; } }

.navLogo {
  height: 100px;
  minWidth: 200px;
  maxWidth: 250px;
  margin: 25px 15px 80px 15px; }

.valertLogoImg {
  height: 80px;
  margin-left: 7px;
  margin-top: 20px; }


.logoVMobile {
  font-size: 2rem;
  text-align: center;
  color: $valert-white;
  line-height: 1;
  margin-left: 12px; }

.logoAlertMobile {
  font-size: 0.75rem;
  text-align: center;
  color: $valert-white;
  line-height: 1;
  margin-left: 12px; }

.sidebar {
  min-height: 100vh;
  height: 100%;
  width: 80px;
  justify-content: center;
  background-color: $valert-white;
  border-right: 1px solid #bdbdbd;
  position: fixed;
  top: 0px !important;
  left: 0px;
  overflow: hidden !important;
  @media only screen and (max-width: 768px) {
    display: none; }

  ul {
    position: absolute;
    top: 275px;
    list-style-type: none;
    padding-left: 25px;
    li {
      padding-bottom: 5px; }
    svg {
      color: $accent-color; } }

  a {
    text-decoration: none;
    font-size: 1.5rem;
    color: white; }
  a:visited {
    color: $valert-gray; }

  .logo-v {
    height: 70px;
    font-size: 4rem;
    text-align: center;
    color: $accent-color; }

  .logo-alert {
    font-size: 1.5rem;
    text-align: center;
    color: $accent-color; } }
