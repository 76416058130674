@import "./Variables";
@import './placeholders';

.checkboxCol {
  align-self: center;
  margin-top: 10px; }

.deviceList {
  margin-top: 10px; }

.deviceListBtn {
  margin-top: 10px; }

.listBtn {
  white-space: nowrap; }

.listCheckBox {
  height: 15px;
  width: 15px; }

.listItems {
  font-size: 12px;
  border: 1px solid black;
  border-radius: 5px;
  list-style: none;
  text-align: center;
  margin-top: 5px;
  height: 60px;
  width: 145px; }

.noDeviceList {
  display: none; }

.selectedDevices {
  border: 1px solid black;
  border-radius: 8px;
  overflow: auto;
  max-height: 200px;
  max-width: 210px;
  padding-left: 5px;
  padding-right: 30px;
  @extend %scrollbar; }

.userCol {
  margin-right: 0px;
  padding-right: 0px; }

.userName {
  font-size: 16px; }
