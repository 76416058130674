@import './Variables';

.cardContainer {
  display: flex;
  flex-direction: column;
  margin: 10px 5px;
  border-radius: 6px;
  overflow: hidden;
  padding: 10px;
  text-overflow: ellipsis;
  min-height: 130px;
  @media only screen and (max-width: 450px) {
    width: 98%; }
  &:hover {
    .editDelete {
      display: flex; } } }

.channelCardContainer {
  display: flex;
  flex-direction: column;
  margin: 10px 0px;
  border-radius: 6px;
  white-space: nowrap;
  overflow: hidden;
  padding: 10px;
  text-overflow: ellipsis;
  @media only screen and (max-width: 450px) {
    width: 98%; }
  &:hover {
    .editDelete {
      display: flex; } } }

.cardContainerFixed {
  display: flex;
  flex-direction: column;
  height: 130px;
  margin: 10px 5px;
  border-radius: 6px;
  overflow: hidden;
  padding: 10px;
  text-overflow: ellipsis;
  @media only screen and (max-width: 450px) {
    width: 98%; }
  &:hover {
    .editDelete {
      display: flex; } } }

.editDelete {
  display: flex;
  flex-direction: row; }

.dataListContainer {
  width: 33%; }

.cardDeleteBtn {
  margin-right: 5px; }

.cardHeading {
  margin: 5px;
  margin-top: -25px;
  text-decoration: underline;
  p {
    font-size: 1.5rem; } }

.channelCardHeading {
  margin: 5px;
  margin-top: -18px;
  p {
    display: block;
    font-size: 1.5rem;
    margin-bottom: 0;
    font-weight: bold; } }

.cardNav {
  display: flex;
  flex-direction: row;
  justify-content: flex-end; }

.cardToggle {
  position: absolute;
  bottom: 10px;
  right: 12px;
  z-index: 10;
  border-radius: 50%;
  border: 2px solid $primary-color;
  padding: 0 7px; }

.columnHeading {
  p {
    margin-top: .25rem;
    margin-bottom: .5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1.3rem;
    @media only screen and (max-width: 450px) {
      font-size: 1rem; } } }

.columnData {
  margin: 0;
  margin-right: 10px;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis; }

.contentContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between; }

.headerData {
  margin: 0px; }
